<template>
  <div class="container">
    <div class="page-header d-flex justify-content-between align-items-center">
      <strong>แท็กเกม</strong>
      <button class="btn btn-sm btn-link" @click="addTag">เพิ่ม</button>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th width="15%">ลำดับ</th>
          <th class="p-1">แท็ก</th>
          <th width="25%" class="p-1">สถานะ</th>
          <th width="120" class="p-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tags">
          <td>{{ item.sort }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.status | tagStatus }}</td>
          <td class="text-center">
            <button class="btn btn-sm btn-warning" @click="editTag(item)"><i class="far fa-edit"></i></button>
            <button class="btn btn-sm btn-info ml-2" @click="gameList(item)"><i class="fa-regular fa-gamepad-modern"></i></button>
          </td>
        </tr>
        <tr v-if="!tags.length">
          <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <GameTagModal :data="data" :is-show="isShowTagModal" @close="modalClosed" />
    <TagGamesModal :tagId="tagId" :is-show="isShowGamesModal" @close="modalClosed" />
  </div>
</template>
<script>
import GameService from '@/services/gameService'
import GameTagModal from './components/GameTagModal'
import TagGamesModal from './components/TagGamesModal'

export default {
  components: {
    GameTagModal,
    TagGamesModal
  },
  data() {
    return {
      tags: [],
      isShowTagModal: false,
      isShowGamesModal: false,
      data: {
        _id: null,
        name: null,
        sort: 0,
        status: 'Active',
        icon: null,
        image: null
      },
      tagId: null
    }
  },
  methods: {
    addTag() {
      this.data = {
        _id: null,
        name: null,
        sort: 0,
        status: 'Active',
        icon: null,
        image: null
      }
      this.isShowTagModal = true
    },
    editTag(item) {
      this.data = {
        _id: item._id,
        name: item.name,
        sort: item.sort,
        status: item.status,
        icon: item.icon,
        image: item.image
      }
      this.isShowTagModal = true
    },
    gameList(item) {
      this.tagId = item._id
      this.isShowGamesModal = true
    },
    loadTags() {
      GameService.getTags().then((response)=>{
        if(response.success) {
          this.tags = response.data
        }
      })
    },
    modalClosed(needReload) {
      this.isShowTagModal = false
      this.isShowGamesModal = false
      if(needReload) {
        this.loadTags()
      }
    }
  },
  mounted() {
    this.loadTags()
  },
  filters: {
    tagStatus(val) {
      return {
        Active: 'ใช้งาน',
        Inactive: 'ไม่ใช้งาน'
      }[val]
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>