<template>
  <b-modal
    ref="add-market-group-modal"
    hide-footer
    :title="modalTitle"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      id="form_level"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="รหัส"
        :invalid-feedback="validate.levelCode.text"
      >
        <b-form-input
          name="level_code"
          v-model="input.levelCode"
          type="text"
          placeholder="รหัส"
          required
          :disabled="!!input._id"
          :state="validate.levelCode.status"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ระดับสมาชิก"
        label-for="input-market-title"
        :invalid-feedback="validate.levelName.text"
      >
        <b-form-input
          name="level_name"
          v-model="input.levelName"
          type="text"
          placeholder="ชื่อระดับสมาชิก"
          required
          :state="validate.levelName.status"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ลำดับชั้น"
        label-for="input-market-priority"
        :invalid-feedback="validate.priority.text"
      >
        <b-form-input
          name="level_priority"
          v-model="input.priority"
          type="number"
          placeholder="ลำดับชั้น"
          :number="true"
          required
          :state="validate.priority.status"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="% ส่วนแบ่ง"
        label-for="input-market-share"
        :invalid-feedback="validate.share.text"
      >
        <b-form-input
          name="level_share"
          v-model="input.share"
          type="number"
          :number="true"
          placeholder="% ส่วนแบ่ง"
          required
          :state="validate.share.status"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="ใช้รหัสหน้า user" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="radio-status"
          v-model="input.skipPrefix"
          :options="skipPrefixOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        ></b-form-radio-group>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess || !input.levelName || !input.levelCode">{{isProcess ? 'กำลังบันทึก...' : modalTitle}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import AccountService from '@/services/accountService'
import { accountLevelInterface } from '@/interfaces/Account'
import Swal from 'sweetalert2'

const validations = {
  levelCode: {
    status: null,
    text: ''
  },
  levelName: {
    status: null,
    text: ''
  },
  priority: {
    status: null,
    text: ''
  },
  share: {
    status: null,
    text: ''
  }
}

export default {
  name: 'AccountLevelModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: accountLevelInterface
    }
  },
  data() {
    return {
      input: accountLevelInterface,
      isProcess: false,
      needReload: false,
      validate: JSON.parse(JSON.stringify(validations))
    }
  },
  computed: {
    modalTitle() {
      return this.input._id ? 'แก้ไขระดับสมาชิก' : 'เพิ่มระดับสมาชิก'
    },
    skipPrefixOptions() {
      return [
        { text: 'ใช้งาน', value: false },
        { text: 'ไม่ใช้งาน', value: true }
      ]
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      let data = JSON.parse(JSON.stringify(this.data))
      this.input = {
        ...accountLevelInterface,
        ...data
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.validate = JSON.parse(JSON.stringify(validations))
    },
    showModal() {
      this.$refs['add-market-group-modal'].show()
    },
    hideModal() {
      this.$refs['add-market-group-modal'].hide()
    },
    handleSubmit() {
      this.validate = JSON.parse(JSON.stringify(validations))
      // this.input.priority = parseInt(this.input.priority)
      // this.input.share = parseFloat(this.input.share)

      if(this.input._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      AccountService.saveLevel(this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.needReload = true
          this.hideModal()
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{

        e?.errors.forEach((item)=>{
          this.validate[item.source.parameter] = {
            status: false,
            text: item?.detail
          }
        })

        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    update() {
      AccountService.updateLevel(this.input._id, this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{

        e?.errors.forEach((item)=>{
          this.validate[item.source.parameter] = {
            status: false,
            text: item?.detail
          }
        })

        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
