<template>
  <div class="container">
    <div class="page-header d-flex justify-content-between align-items-center">
      <strong>หมวดเกม</strong>
      <button class="btn btn-sm btn-link" @click="addCat">เพิ่ม</button>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th width="15%">ลำดับ</th>
          <th class="p-1">หมวด</th>
          <th width="25%" class="p-1">สถานะ</th>
          <th width="60" class="p-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in categories">
          <td>{{ item.sort }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.status | catStatus }}</td>
          <td class="text-right">
            <button class="btn btn-sm btn-outline-info" @click="editCat(item)"><i class="far fa-edit"></i></button>
          </td>
        </tr>
        <tr v-if="!categories.length">
          <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <GameCatModal :data="data" :is-show="isShowCatModal" @close="modalClosed" />
  </div>
</template>
<script>
import GameService from '@/services/gameService'
import GameCatModal from './components/GameCatModal'

export default {
  components: {
    GameCatModal
  },
  data() {
    return {
      categories: [],
      isShowCatModal: false,
      data: {
        _id: null,
        name: null,
        sort: 0,
        status: 'Active',
        icon: null,
        image: null
      }
    }
  },
  methods: {
    addCat() {
      this.data = {
        _id: null,
        name: null,
        sort: 0,
        status: 'Active',
        icon: null,
        image: null
      }
      this.isShowCatModal = true
    },
    editCat(item) {
      this.data = {
        _id: item._id,
        name: item.name,
        sort: item.sort,
        status: item.status,
        icon: item.icon,
        image: item.image
      }
      this.isShowCatModal = true
    },
    loadCats() {
      GameService.getCategories().then((response)=>{
        if(response.success) {
          this.categories = response.data
        }
      })
    },
    modalClosed(needReload) {
      this.isShowCatModal = false
      if(needReload) {
        this.loadCats()
      }
    }
  },
  mounted() {
    this.loadCats()
  },
  filters: {
    catStatus(val) {
      return {
        Active: 'ใช้งาน',
        Inactive: 'ไม่ใช้งาน'
      }[val]
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
