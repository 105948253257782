<template>
  <b-modal
    ref="tag-games-modal"
    hide-footer
    :title="modalTitle"
    @show="loadTag"
    size="lg"
  >

  <form class="form-inline" id="tag-games-form" ref="form" @submit.stop.prevent="handleSubmit">	
    <div class="form-group mb-2">
      เพิ่มเกม
    </div>
    <div class="form-group mx-sm-3 mb-2">
      <input type="text" v-model="addGameId" class="form-control form-control-sm" placeholder="ใส่ GameId">
    </div>
    <button type="submit" class="btn btn-primary btn-sm mb-2">เพิ่มเกม</button>
  </form>

  <table class="table table-sm table-striped table-bordered table-hover mb-0">	
    <thead>
      <tr>
        <th width="60" class="text-center">รูป</th>
        <th>ชื่อเกม</th>
        <th width="80" class="text-center">เปิดใช้</th>
        <th width="60" class="text-center">จัดการ</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="game in games">
        <td>
          <img :src="game.img" style="width: 50px; height: 50px;">
        </td>
        <td>{{ game.name }}</td>
        <td class="text-center">{{ game.isActive }}</td>
        <td class="text-center">
          <button class="btn btn-sm btn-outline-danger" @click="removeGame(game._id)"><i class="far fa-trash-alt"></i></button>
        </td>
      </tr>
      <tr v-if="!games.length">
        <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
      </tr>
    </tbody>
  </table>

  </b-modal>
</template>
<script>
import GameService from '@/services/gameService'
import Swal from 'sweetalert2'

export default {
  name: 'TagGamesModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    tagId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      data: null,
      isLoading: false,
      addGameId: null
    }
  },
  computed: {
    modalTitle() {
      return 'เกมที่เกี่ยวข้องกับแท็ก ::: ' + this.tagName
    },
    tagName() {
      return this.data ? this.data.tag.name : ''
    },
    games() {
      return this.data ? this.data.games : []
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    showModal() {
      this.addGameId = null
      this.$refs['tag-games-modal'].show()
    },
    hideModal() {
      this.addGameId = null
      this.$refs['tag-games-modal'].hide()
    },
    loadTag() {
      this.isLoading = true
      GameService.getTag(this.tagId).then((response)=>{
        if(response.success) {
          this.data = response.data
        }
        this.isLoading = false
      })
      .catch(()=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    handleSubmit() {
      if(!this.addGameId)
        return

      GameService.addGameToTag(this.tagId, {gameIds: [this.addGameId]}).then((response)=>{
        if(response.success) {
          this.addGameId = null
          this.loadTag()
        }
      })
      .catch(()=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    removeGame(gameId) {
      Swal.fire({
        title: 'ยืนยันการลบข้อมูล',
        text: 'คุณต้องการลบข้อมูลใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่ใช่'
      }).then((result)=>{
        if(result.isConfirmed) {
          GameService.removeGameFromTag(this.tagId, gameId).then((response)=>{
            if(response.success) {
              this.loadTag()
            }
          })
          .catch(()=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  tr {
    td {
      vertical-align: middle;
    }
  }
}
</style>